<template>
  <tr
    class="tr-toggled-content"
    :class="{ 'not-in-unit': !getElementUnitState && _manageElementsState }"
    :style="{ 'background-color': setBg(prevRowBg, 10) }"
    v-show="
      (!this.child.element.inUnit && _manageElementsState) ||
      this.child.element.inUnit
    "
  >
    <td @click="[getElements(), toggleChild()]" class="my-td item-hover">
      <div class="flex-row" :style="`padding-left: ${childPadding}px;`">
        <p class="-stb code">
          {{ getElementCode }}
        </p>
        <i
          v-show="this.child?.element?.code?.length < 14"
          class="i-arrow-open-down"
          :class="{ rotate: child.toggled }"
        ></i>
      </div>
    </td>
    <td @click="[getElements(), toggleChild()]" class="my-td item-hover">
      <!-- <p class="-str" :class="getClassFromSpecificationLvl">
        {{ getElementName }}
      </p> -->

      <div class="flex justify-space-between gap4">
        <p class="-xstr" :class="getClassFromSpecificationLvl">
          {{ getElementName }}
        </p>
        <p
          class="-stb"
          :class="getClassFromSpecificationLvl"
          :title="`Contains ${getChildrenCount} more codes`"
        >
          {{ getChildrenCount }}
        </p>
      </div>
    </td>

    <!-- <td class="my-td">
      <section v-show="getElementUnitState">
        <div class="flex-row" v-if="!child.agent || !child.agent.length">
          <DynaBadge />
          <div class="flex-column">
            <p class="-stb">Not assigned</p>
          </div>
        </div>
        <div class="flex-row" v-else>
          <DynaBadge :first_name="child.agent[0].agent_name" />
          <div class="flex-column">
            <p class="-stb">
              {{ child.agent[0].agent_name }}
            </p>
            <p class="-xstr">Assign user</p>
          </div>
        </div>
      </section>
    </td> -->
    <td class="my-td" style="text-align: center">
      <div class="lodloi-container" :class="{ isToggled: child.toggled }">
        <p class="-stb flex-align">3</p>
      </div>
    </td>
    <td class="my-td" style="text-align: center">
      <div class="lodloi-container" :class="{ isToggled: child.toggled }">
        <p class="-stb flex-align">3</p>
      </div>
    </td>
    <td class="my-td flex justify-end gap4" colspan="5">
      <IconSpecify title="Specify Element" v-show="!getElementUnitState" />
      <!-- @click="setModal('create', [getElement])" -->
      <IconRelatedTask v-show="getElementUnitState" title="Related Tasks" />
      <IconOpenModel v-show="getElementUnitState" title="Open Model" />
      <IconViewDocument v-show="getElementUnitState" title="Open Document" />
      <IconEdit v-show="getElementUnitState" title="Edit Row" />
      <!-- @click="setModal('update', [getElement])" -->
    </td>
  </tr>
  <template v-if="child.toggled">
    <ElementsChildListing
      :childPadding="childPadding + 4"
      :child="item"
      :prevRowBg="setBg(prevRowBg, 6)"
      :_manageElementsState="_manageElementsState"
      v-for="item in child.children"
      :key="item"
      @setModal="setModal"
    />
  </template>
</template>

<script>
import IconTrash from "../../../../assets/icons/dynamicIcons/IconTrash.vue";
import IconEdit from "../../../../assets/icons/dynamicIcons/IconEdit.vue";
import IconRelatedTask from "../../../../assets/icons/dynamicIcons/IconRelatedTask.vue";
import IconOpenModel from "../../../../assets/icons/dynamicIcons/IconOpenModel.vue";
import IconViewDocument from "../../../../assets/icons/dynamicIcons/IconViewDocument.vue";
import IconSpecify from "../../../../assets/icons/dynamicIcons/IconSpecify.vue";
import DynaBadge from "../../../utils/DynaBadge.vue";
import ElementsChildListing from "./ElementsChildListing.vue";
import {
  getUnitElements,
  getNotUnitElements,
} from "../../../../services/requests/get/elements";
import {
  setInUnitEls,
  setNotInUnitEls,
  setNotInUnitChildren,
  mergeElements,
} from "../../../../helpers/elementsDataFilters";
export default {
  props: ["child", "childPadding", "prevRowBg", "_manageElementsState"],
  emits: ["setModal"],
  name: "ElementsChildListing",
  components: {
    IconTrash,
    IconEdit,
    IconRelatedTask,
    IconOpenModel,
    IconViewDocument,
    IconSpecify,
    DynaBadge,
    ElementsChildListing,
  },
  data() {
    return {
      MAXLENGTH: 14,
    };
  },
  // created() {
  //   console.log("CREATED LH: ", this.child);
  // },
  methods: {
    async getElements() {
      // if (!this.child.children_count) return;

      // if (typeof this.child.toggled !== undefined) return;
      //make api call

      if (this.child?.element?.code?.length === this.MAXLENGTH) return;

      const res = this.child.element.inUnit
        ? await getUnitElements(this.child.id)
        : await getNotUnitElements(this.child.element.id);

      if (res.status !== 200) return;

      console.log("RES DATA: ", res.data);

      this.child.element.inUnit
        ? this.setChildren(res.data)
        : (this.child.children = setNotInUnitChildren(res.data));
    },
    toggleChild() {
      if (this.child?.element?.code?.length === this.MAXLENGTH) return;
      this.child.toggled = !this.child.toggled;
    },
    setChildren(data) {
      const inUnitEls = setInUnitEls(data);
      const notInUnitEls = setNotInUnitEls(data);
      this.child.children = mergeElements(inUnitEls, notInUnitEls);
    },

    setBg(color, amount) {
      //this will return a lighter color depending on the amount
      const num = parseInt(color.slice(1), 16);
      // shift the bits N places to the right, then add the amount to it
      let r = (num >> 16) + amount;
      let b = ((num >> 8) & 0x00ff) + amount;
      let g = (num & 0x0000ff) + amount;

      r = r > 255 ? 255 : r < 0 ? 0 : r;
      b = b > 255 ? 255 : b < 0 ? 0 : b;
      g = g > 255 ? 255 : g < 0 ? 0 : g;

      const newColor =
        "#" + (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0");

      return newColor;
    },
    setModal(cmd, item) {
      const filteredItem = this.setModalItems(item);
      this.$emit("setModal", cmd, filteredItem);
    },
    setModalItems(item) {
      const { code } = item[0];
      const { element } = this.child;

      if (element.code === code) return item;
      if (element.inUnit) return item;

      return [element, ...item];
    },
  },
  computed: {
    getElement() {
      return this.child.element;
    },
    getElementCode() {
      const { code } = this.child.element;
      switch (code.length) {
        case 8:
          return `· ${code}`;
        case 11:
          return `· · ${code}`;
        case 14:
          return `· · · ${code}`;
        default:
          return code;
      }
    },
    getElementUnitState() {
      return this.child.element.inUnit;
    },
    getElementName() {
      return this.child.element.name;
    },
    getChildrenCount() {
      return this.child.children_count ? `+ ${this.child.children_count}` : "";
    },
    getClassFromSpecificationLvl() {
      const codeLength = this.child.element.code.length;

      switch (codeLength) {
        case 8:
          return "subgroup";
        case 11:
          return "section";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.code {
  position: relative;
}
.tr-toggled-content {
  animation: slide-from-top 200ms ease-out;
  position: relative;
}
.subgroup {
  /* bold - lettercase; */
  font-weight: bold;
  font-variant: small-caps;
  font-size: 14px;
}

.section {
  /* regular - uppercase; */
  font-weight: bold;
  font-size: 13px;
}

@keyframes slide-from-top {
  from {
    transform: translateY(-50%);
    z-index: 0;
  }
  to {
    transform: translateY(0);
    z-index: 1;
  }
}
</style>
