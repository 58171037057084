<template>
  <main class="table-container" v-if="elements.length">
    <table class="my-table">
      <thead
        :class="[!_manageElementsState ? 'my-thead' : 'managing-my-thead']"
      >
        <tr>
          <th class="my-th" style="min-width: 150px">
            <p class="-stb">Classification</p>
          </th>
          <th class="my-th" style="min-width: 300px">
            <p class="-stb">Element Title</p>
          </th>
          <!-- <th class="my-th" style="min-width: 170px">
            <p class="-stb">Responsible</p>
          </th> -->
          <th
            class="my-th"
            style="min-width: 44px; max-width: 44px; text-align: center"
          >
            <p class="-stb">LOD</p>
          </th>
          <th
            class="my-th"
            style="min-width: 44px; max-width: 44px; text-align: center"
          >
            <p class="-stb">LOI</p>
          </th>
          <th class="my-th" colspan="5"></th>
        </tr>
      </thead>
      <tbody v-for="(element, index) in elements" :key="element">
        <tr
          class="tr-content"
          :class="[
            index % 2 ? 'bg-primary' : 'bg-secondary',
            {
              'not-in-unit':
                !getElementUnitState(index) && _manageElementsState,
            },
            { toggledBg: element.toggled },
          ]"
        >
          <td
            @click="[getElements(element), toggleElement(element)]"
            class="my-td item-hover"
          >
            <div class="flex-row">
              <p class="-stb">{{ getElementCode(index) }}</p>
              <i
                v-show="!!getChildrenCount(index)"
                class="i-arrow-open-down"
                :class="{ rotate: element.toggled }"
              ></i>
            </div>
          </td>
          <td
            @click="[getElements(element), toggleElement(element)]"
            class="my-td item-hover"
          >
            <div class="flex justify-space-between gap4">
              <p class="-stb">{{ getElementName(index) }}</p>
              <p
                class="-stb"
                :title="`Contains ${getChildrenCount(index).slice(
                  1
                )} more codes`"
              >
                {{ getChildrenCount(index) }}
              </p>
            </div>
          </td>

          <!-- <td class="my-td">
            <section v-show="getElementUnitState">
              <div class="flex-row">
                <DynaBadge />
                <div class="flex-column">
                  <p class="-stb">Not assigned</p>
                </div>
              </div>
            </section> -->

          <!-- <div class="flex-row" v-if="element.user">
              <DynaBadge
                :picturePath="element.user.picture"
                :first_name="element.user.first_name"
                :last_name="element.user.last_name"
              />
              <div class="flex-column">
                <p
                  class="-stb"
                  v-if="element.agent.length && !element.children.length"
                >
                  {{ element.agent }}
                </p>
                <p class="-stb" v-else>Not Assigned</p>
                <p class="-xstr" v-if="element.user">
                  {{ element.user.first_name }} {{ element.user.last_name }}
                </p>
              </div>
            </div> -->
          <!-- </td> -->
          <td class="my-td" style="text-align: center">
            <div
              class="lodloi-container"
              :class="{ isToggled: element.toggled }"
            >
              <!-- <p class="-stb flex-align">{{ element.lod }}</p> -->
              <p class="-stb flex-align">3</p>
            </div>
          </td>
          <td class="my-td" style="text-align: center">
            <div
              class="lodloi-container"
              :class="{ isToggled: element.toggled }"
            >
              <!-- <p class="-stb flex-align">{{ element.loi }}</p> -->
              <p class="-stb flex-align">3</p>
            </div>
          </td>
          <td class="my-td flex justify-end gap4" colspan="5">
            <IconSpecify
              title="Specify Element"
              v-show="!getElementUnitState(index)"
            />
            <!-- @click="setModal('create', [getElement(index)])" -->
            <IconRelatedTask
              v-show="getElementUnitState(index)"
              title="Related Tasks"
            />
            <IconOpenModel
              v-show="getElementUnitState(index)"
              title="Open Model"
            />
            <IconViewDocument
              v-show="getElementUnitState(index)"
              title="Open Document"
            />
            <IconEdit v-show="getElementUnitState(index)" title="Edit Row" />
            <!-- @click="setModal('update', [getElement(index)])" -->
          </td>
        </tr>

        <template v-for="child in element.children" :key="child">
          <ElementsChildListing
            v-if="element.toggled"
            :child="child"
            :prevRowBg="toggledBg"
            :childPadding="childPadding"
            :_manageElementsState="_manageElementsState"
            @setModal="setModal"
          />
        </template>
      </tbody>
    </table>
  </main>
  <main class="no-data" v-else>
    <NoData :item="'element'" />
  </main>
</template>

<script>
import IconTrash from "../../../../assets/icons/dynamicIcons/IconTrash.vue";
import IconEdit from "../../../../assets/icons/dynamicIcons/IconEdit.vue";
import IconRelatedTask from "../../../../assets/icons/dynamicIcons/IconRelatedTask.vue";
import IconOpenModel from "../../../../assets/icons/dynamicIcons/IconOpenModel.vue";
import IconViewDocument from "../../../../assets/icons/dynamicIcons/IconViewDocument.vue";
import IconSpecify from "../../../../assets/icons/dynamicIcons/IconSpecify.vue";
import DynaBadge from "../../../utils/DynaBadge.vue";
import NoData from "../../../utils/NoData.vue";
import ElementsChildListing from "./ElementsChildListing.vue";
import {
  getUnitElements,
  getNotUnitElements,
} from "../../../../services/requests/get/elements";
import {
  setInUnitEls,
  setNotInUnitEls,
  setNotInUnitChildren,
  mergeElements,
} from "../../../../helpers/elementsDataFilters";
export default {
  components: {
    IconTrash,
    IconEdit,
    IconRelatedTask,
    IconOpenModel,
    IconViewDocument,
    IconSpecify,
    DynaBadge,
    NoData,
    ElementsChildListing,
  },
  props: ["elements", "_manageElementsState"],
  emits: ["setModal"],
  data() {
    return {
      onBehalf: false,
      childPadding: 4,
      testElement: null,
      // toggledBg: "#d2e0ff",
      toggledBg: "#e8efff",
    };
  },
  methods: {
    async getElements(element) {
      if (element.toggled !== undefined) return;
      //make api call

      const res = element.element.inUnit
        ? await getUnitElements(element.id)
        : await getNotUnitElements(element.element.id);

      if (res.status !== 200) return;

      element.element.inUnit
        ? this.setChildren(res.data, element)
        : (element.children = setNotInUnitChildren(res.data));
    },
    setModal(cmd, item) {
      const filteredItem = this.setModalItems(item);
      this.$emit("setModal", cmd, filteredItem);
    },
    setModalItems(elementsArray) {
      //deconstruct the object and check if the item param is the parent. If it is and it is not in unit return it
      const { inUnit, code } = elementsArray[0];

      if (code.length === 5 && !inUnit) {
        console.log("NIU PARENT", elementsArray);
        return elementsArray;
      }

      //get parent element from the sliced code of the first child
      const [{ element: newElement }] = this.elements.filter(
        ({ element: { code: newCode } }) => newCode === code.slice(0, 5)
      );
      if (newElement.inUnit) return elementsArray;

      console.log(typeof elementsArray);

      //if parent is not in unit, return the new array with the parent as the first element
      return [newElement, ...elementsArray];
    },

    getElement(index) {
      return this.elements[index].element;
    },
    getElementCode(index) {
      return this.elements[index].element.code;
    },
    getElementUnitState(index) {
      return this.elements[index].element.inUnit;
    },
    getElementName(index) {
      return this.elements[index].element.name.toUpperCase();
    },
    getChildrenCount(index) {
      return this.elements[index].children_count
        ? `+${this.elements[index].children_count}`
        : "";
    },
    setChildren(data, element) {
      const inUnitEls = setInUnitEls(data);
      const notInUnitEls = setNotInUnitEls(data);
      element.children = mergeElements(inUnitEls, notInUnitEls);
    },
    toggleElement(element) {
      element.toggled = !element.toggled;
    },
  },
};
</script>

<style scoped>
/*TABLE*/
:deep(.not-in-unit) {
  /* color: var(--focus-text) !important; */
  color: var(--gray4) !important;
}
:deep(.bg-primary) {
  background: #f9fafb !important;
}
:deep(.bg-secondary) {
  background: var(--white1) !important;
}
:deep(.table-container) {
  width: 100%;
  height: 100%;
}
:deep(.item-hover) {
  cursor: pointer;
}
.my-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
:deep(.my-thead),
:deep(.managing-my-thead) {
  background-size: 500% auto;
  transition: background-image 0.5s ease-in-out;
}

:deep(.my-thead) {
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
}
:deep(.managing-my-thead) {
  background-image: linear-gradient(
    to right,
    #1a0b2e 0%,
    var(--focus-text) 51%,
    #1a0b2e 100%
  );
}

:deep(.my-th) {
  padding: 8px;
  color: var(--white1);
}
:deep(.my-th:first-child) {
  border-top-left-radius: 8px;
}
:deep(.my-th:last-child) {
  border-top-right-radius: 8px;
}
:deep(.my-td) {
  padding: 8px;
}
:deep(tr) {
  user-select: none;
}
:deep(.tr-content),
:deep(.tr-toggled-content) {
  transition: box-shadow 400ms ease;
  cursor: pointer;
}

:deep(.flex-row) {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
}
:deep(.flex-column) {
  display: flex;
  flex-direction: column;
}
:deep(.flex-column p:last-child) {
  color: var(--gray2);
}
:deep(.toggled) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  padding-inline: 12px;
}
:deep(.toggled-text) {
  color: var(--gray2);
}
/*ICONS*/
:deep(i) {
  transform: scale(0.5);
  cursor: pointer;
}
:deep(.rotate) {
  transform: rotateZ(180deg) scale(0.5);
}
:deep(thead) {
  position: sticky;
  top: 0;
  z-index: 2;
}

:deep(.lodloi-container) {
  width: 100%;
  display: flex;
  justify-content: center;
  user-select: none;
}
:deep(.lodloi-container p) {
  border-radius: 4px;
  padding-block: 4px;
  padding-inline: 8px;
  background: rgb(206, 238, 255);
  background: linear-gradient(
    156deg,
    rgba(206, 238, 255, 1) 0%,
    rgba(199, 223, 255, 1) 100%
  );
}
:deep(.toggledBg),
:deep(.toggledBg:hover) {
  /* background: #cddcff !important; */
  background: #e8efff !important;
}
/* :deep(.tr-content:hover .i-arrow-open-down),
:deep(.tr-toggled-content:hover .i-arrow-open-down) {
  scale: 1.2 !important;
} */
:deep(.tr-content:hover),
:deep(.tr-toggled-content:hover) {
  /* background: rgb(243, 248, 254) !important; */
  box-shadow: inset 0 1px 0 #1522439d, inset 0 -1px 0 #1522439d;
}
:deep(.tr-toggled-content:hover) {
  background: var(--focus3) !important;
}

:deep(.isToggled) {
  opacity: 0.25;
}
</style>
