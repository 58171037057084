<template>
  <main class="content-page">
    <section class="submenu">
      <span class="highlight"></span>
      <h1 class="-h6">{{ getTitle }}</h1>

      <section class="actions">
        <div
          class="btn-container"
          :class="{ 'btn-container-managing-state': _manageElementsState }"
        >
          <Button
            :state="'primary'"
            :title="getBtnTitle"
            @click="_manageElementsState = !_manageElementsState"
          />
        </div>
        <div class="search-container">
          <SearchBar />
        </div>
      </section>
    </section>

    <div class="listing">
      <ElementsListing
        :elements="getElements"
        :_manageElementsState="_manageElementsState"
        @setModal="setModal"
      />
    </div>
  </main>
</template>

<script>
import SearchBar from "../../../components/utils/BaseSearchBar.vue";
import Button from "../../../components/utils/BaseButton.vue";
import ElementsListing from "../../../components/tables/mscope/elements/ElementsListing.vue";
import { getUnitRootElements } from "../../../services/requests/get/elements";
import {
  setInUnitEls,
  setNotInUnitEls,
  mergeElements,
} from "../../../helpers/elementsDataFilters";
export default {
  components: { SearchBar, Button, ElementsListing },
  props: ["getRouteName"],
  data() {
    return {
      elements: [],
      _manageElementsState: false,
    };
  },
  async created() {
    //get elements from api
    const res = await getUnitRootElements();
    if (res.status != 200) return;
    //console.log("ELEMETNS RES DATA: ", res.data);
    this.setElements(res.data);
  },
  methods: {
    setModal(cmd, item) {
      console.log("ITEM BEING PASSED: ", item);
      const route = this.getRouteName();
      const scopeModal = {
        cmd: cmd,
        scope: route,
        item: item,
      };
      this.$store.dispatch("scopeModal", scopeModal);
    },
    setElements(data) {
      const inUnitEls = setInUnitEls(data);
      const notInUnitEls = setNotInUnitEls(data);
      this.elements = mergeElements(inUnitEls, notInUnitEls);
      console.log("SORTED ELS: ", this.elements);
    },
  },
  computed: {
    getTitle() {
      return !this._manageElementsState
        ? "Elements"
        : "Elements - Managing View";
    },
    getElements() {
      //if manageElementsState is true, it will return the elements array where the element.inUnit is true
      return !this._manageElementsState
        ? this.elements.filter((item) => item.element.inUnit)
        : this.elements;
    },
    getBtnTitle() {
      return !this._manageElementsState ? "Manage Elements" : "Close Manager";
    },
  },
};
</script>

<style scoped>
* {
  --submenu-height: 40px;
}
.submenu > .-h6 {
  white-space: nowrap;
}
.content-page {
  height: calc(100% - 4px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.submenu {
  width: 100%;
  height: var(--submenu-height);
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
  padding-top: 8px;
}

.listing {
  width: 100%;
  height: calc(100% - var(--submenu-height));
  overflow: auto;
  animation: slide-in-top-menu 600ms ease-out;
}

.highlight {
  display: inline-block;
  height: 16px;
  width: 1px;
  border-radius: 8px;
  border: 2px solid var(--highlight);
  background: var(--highlight);
}

.submenu h1 {
  text-transform: uppercase;
  padding-left: 8px;
}

.actions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.search-container {
  height: 100%;
  width: 272px;
  text-align: right;
}

.btn-container {
  width: 164px;
  height: 100%;
}
.btn-container-managing-state {
  filter: hue-rotate(40deg);
}

@media screen and (max-width: 1023px) {
  .submenu {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid black; */
    gap: 16px;
  }

  .highlight {
    display: none;
  }

  .actions {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
