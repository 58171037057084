import apiClient from "../../axios/axios";
import store from "../../../store/index";

export async function getUnitRootElements() {
  try {
    const proj_unit_id = store.state.unitModule.unit.id;
    const endpoint = `/showElementRoot/${proj_unit_id}`;

    const res = await apiClient.get(endpoint);
    //console.log("RES FROM SERVICES", res);

    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on agents store getter");
  }
}

//API request to get all elements, given the parent element id
export async function getUnitElements(parent_ele_id) {
  try {
    const proj_unit_id = store.state.unitModule.unit.id;
    const endpoint = `/showElements`;
    const data = {
      proj_unit_id: proj_unit_id,
      parent_ele_id: parent_ele_id,
    };

    const res = await apiClient.post(endpoint, data);
    console.log("RES FROM SERVICES", res);

    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on agents store getter");
  }
}

//API request to get all not-in-unit elements, given the parent element id
export async function getNotUnitElements(parent_id) {
  try {
    const endpoint = `/showAllElements`;
    const data = {
      parent_id: parent_id,
    };

    const res = await apiClient.post(endpoint, data);
    console.log("RES FROM SERVICES", res);

    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on agents store getter");
  }
}
