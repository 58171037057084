//THIS CAN BE ABSTRACTED FURTHER TO BE MORE REUSABLE IF NEEDED

//convert response object to the correct format: array of objects and filter out the ones with no id
export const setInUnitEls = (data) =>
  Object.values(data) //returns an array containing all of the object's values
    .map(({ element, ...rest }) => ({
      //destructure object to get element and the rest of the properties
      ...rest,
      element: {
        ...element,
        inUnit: true, //add new property inUnit to facilitate the rendering of the tree
      },
    }))
    //filter out any objects where the id property is falsy - the elements with no id are not in the unit
    .filter(({ id }) => id);

//convert notInPers to the same format as inUnitEls (element is a property of the object that is also an array)
//also adds new property inUnit to each element to facilitate the rendering of the tree
export const setNotInUnitEls = (data) =>
  data.notInPers.map(({ element, ...rest }) => ({
    ...rest,
    element: {
      ...element,
      inUnit: false,
    },
  }));

export const setNotInUnitChildren = (data) =>
  data.map(({ element, ...rest }) => ({
    ...rest,
    element: {
      ...element,
      inUnit: false,
    },
  }));

export const mergeElements = (inUnitEls, notInUnitEls) =>
  //returns an array of objects with the elements from both arrays and sort them by the property element.code which is a string
  [...inUnitEls, ...notInUnitEls].sort((a, b) =>
    a.element.code.localeCompare(b.element.code)
  );
