<template>
  <main class="icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.49 8.59L6.51 15.42M17.49 15.41L13.51 8.59M19 21C17.3431 21 16 19.6569 16 18C16 16.3431 17.3431 15 19 15C20.6569 15 22 16.3431 22 18C22 19.6569 20.6569 21 19 21ZM12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6C15 7.65685 13.6569 9 12 9ZM5 21C3.34315 21 2 19.6569 2 18C2 16.3431 3.34315 15 5 15C6.65685 15 8 16.3431 8 18C8 19.6569 6.65685 21 5 21Z"
        stroke="#667085"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.icon:hover path {
  stroke: var(--highlight);
}
</style>